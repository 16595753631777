@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  font-size: 24px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-carousel:hover .slick-prev,
.ant-carousel:hover .slick-next {
  font-size: inherit !important;
  color: white !important;
  z-index: 1 !important;
  left: 0 !important;
  width: 100% !important;
  height: 40px !important;
}

.ant-carousel .slick-prev {
  top: 100px !important;
}

.ant-carousel .slick-next {
  top: calc(100% - 60px) !important;
}

.ant-carousel:has(.slick-prev:hover) .ant-image-mask {
  opacity: 1;
}

.ant-carousel:has(.slick-next:hover) .ant-image-mask {
  opacity: 1;
}

@media (max-width: 640px) {
  .ant-picker-panel:last-child .ant-picker-date-panel {
    display: none !important;
  }

  .ant-picker-panel:first-child .ant-picker-date-panel .ant-picker-header-next-btn,
  .ant-picker-panel:first-child .ant-picker-date-panel .ant-picker-header-super-next-btn {
    visibility: initial !important;
  }

  .ant-picker-panel.ant-picker-range-wrapper {
    min-width: 288px !important;
  }

  .ant-picker-presets {
    display: none !important;
  }
}
